import FingoTable from '@fingo/lib/components/tables/FingoTable';
import { useInvoicePreColumns } from '@fingo/lib/constants';
import PropTypes from 'prop-types';
import React from 'react';

const CollapseInvoiceRows = ({ operativeRequestManagers }) => {
  const invoices = operativeRequestManagers.map((manager) => manager.invoice);
  return (
    <FingoTable
      rows={invoices}
      columns={useInvoicePreColumns()}
      includeHeaders={[
        'simpleFolio',
        'amountWithIva',
        'company_Name',
        'receiver_sortable',
        'simpleStatus',
      ]}
      pagination={false}
    />
  );
};

CollapseInvoiceRows.propTypes = {
  operativeRequestManagers: PropTypes.arrayOf({
    id: PropTypes.string,
    invoice: PropTypes.shape({
      id: PropTypes.string,
      folio: PropTypes.string,
      amountWithIva: PropTypes.number,
    }),
  }).isRequired,
};

export default CollapseInvoiceRows;
