import FingoTable from '@fingo/lib/components/tables/FingoTable';
import { useOrderingLucilaPreColumns } from '@fingo/lib/constants';
import PropTypes from 'prop-types';
import React from 'react';

const CollapsePurchaseOrderRows = ({ operativeRequestManagers }) => {
  const purchaseOrders = operativeRequestManagers.map((manager) => manager.purchaseOrder);
  return (
    <FingoTable
      rows={purchaseOrders}
      columns={useOrderingLucilaPreColumns()}
      includeHeaders={[
        'orderNumber',
        'totalAmount',
        'company_Name',
        'purchaser_Name',
        'orderingStatus',
      ]}
      pagination={false}
    />
  );
};

CollapsePurchaseOrderRows.propTypes = {
  operativeRequestManagers: PropTypes.arrayOf({
    id: PropTypes.string,
    purchaseOrders: PropTypes.shape({
      id: PropTypes.string,
      orderNumber: PropTypes.string,
      amountWithIva: PropTypes.number,
    }),
  }).isRequired,
};

export default CollapsePurchaseOrderRows;
