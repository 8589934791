/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { MoneyFields } from '@fingo/lib/graphql/moneyFields';
import { UserIdentification } from '@fingo/lib/graphql/user/fragments';

export const OPERATIVE_REQUESTS = gql`
  query operativeRequests(
    $offset: Int,
    $before: String,
    $after: String,
    $first: Int,
    $last: Int,
    $id_In: [String],
    $status: String,
  ) {
    operativeRequests(
      offset: $offset,
      before: $before,
      after: $after,
      first: $first,
      last: $last,
      id_In: $id_In,
      status: $status,
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        node {
          id
          status
          comment
          operativeRequestType {
            id
            shortName
            name
            code
          }
          requester {
            ... UserIdentification
          }
          operativerequestmanagerSet {
            id
            invoice {
              ... on InvoiceType {
                id
                folio
                amountWithIva {
                  ...MoneyFields
                }
                status
                receiver {
                  name
                  id
                  rut
                  displayNationalIdentifier
                }
                company {
                  id
                  masterEntity {
                    id
                    name
                    rut
                    displayNationalIdentifier
                  }
                }
              }
            }
            purchaseOrder {
              ... on OrderingPurchaseOrderType {
                id
                orderNumber
                status
                totalAmount {
                  ...MoneyFields
                }
                company {
                  id
                  masterEntity {
                    id
                    name
                    rut
                    displayNationalIdentifier
                  }
                }
                purchaser {
                  name
                  id
                  rut
                  displayNationalIdentifier
                }
                orderingoffer {
                  id
                  orderingFinancedAmount {
                    ...MoneyFields
                  }
                }
                orderingsimulation {
                  id
                  orderingFinancedAmount {
                    ...MoneyFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${MoneyFields}
  ${UserIdentification}
`;
