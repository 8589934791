import { useMutation } from '@apollo/client';
import { DialogWithTextField } from '@fingo/lib/components/dialogs';
import { REJECT_OPERATIVE_REQUEST } from '@fingo/lib/graphql';
import { useBooleanState, useSnackBars, useTextFieldInput } from '@fingo/lib/hooks';
import Cancel from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';

const RejectRequestButton = ({ operativeRequestId }) => {
  const [comment, setComment, resetInput] = useTextFieldInput();
  const [openDialog, toggleDialog] = useBooleanState();
  const { addAlert } = useSnackBars();
  const [
    rejectOperativeRequest,
    { loading: loadingMutation },
  ] = useMutation(REJECT_OPERATIVE_REQUEST, {
    refetchQueries: ['operativeRequests'],
    variables: {
      operativeRequestId,
      parameters: [{
        key: 'comment',
        value: comment,
      }],
    },
    onCompleted: () => {
      resetInput();
      toggleDialog();
      addAlert({ id: 'reject-operative-request', color: 'success', message: '¡Solicitud rechazada!' });
    },
  });
  return (
    <>
      <IconButton
        onClick={toggleDialog}
        disabled={!operativeRequestId}
      >
        <Cancel color="error" />
      </IconButton>
      <DialogWithTextField
        openDialog={openDialog}
        onCloseDialog={toggleDialog}
        title={`Rechazar solicitud N°${operativeRequestId}`}
        onSubmit={rejectOperativeRequest}
        loadingSubmit={loadingMutation}
        textFieldLabel="Motivo del rechazo"
        textInput={comment}
        setTextInput={setComment}
      />
    </>
  );
};

RejectRequestButton.propTypes = {
  operativeRequestId: PropTypes.string.isRequired,
};

export default RejectRequestButton;
