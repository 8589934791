import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { OPERATIVE_REQUESTS_COLUMNS } from '../../constants/operative-requests-columns';
import { OPERATIVE_REQUESTS } from '../../graphql/operative-requests';
import OperativeRequestActions from './Actions/OperativeRequestActions';
import CollapseInvoiceRows from './CollapseComponents/InvoiceRows';
import CollapsePurchaseOrderRows from './CollapseComponents/PurchaseOrderRows';

const isInvoice = (row) => row.operativerequestmanagerSet[0]?.invoice;

const CollapseOperativeRequest = ({ row }) => {
  if (isInvoice(row)) {
    return (
      <CollapseInvoiceRows
        operativeRequestManagers={row.operativerequestmanagerSet}
      />
    );
  }
  return (
    <CollapsePurchaseOrderRows operativeRequestManagers={row.operativerequestmanagerSet} />
  );
};

const OperativeRequests = () => {
  const [_selectedRequest, _setSelectedRequest] = useState([]);
  const setSelectedRequest = (requestIds) => {
    _setSelectedRequest((prev) => [requestIds.find((id) => !prev.includes(id))]);
  };
  const selectedRequest = _selectedRequest[0];

  return (
    <FingoMainView
      id="operative-requests"
      query={OPERATIVE_REQUESTS}
      queryCustomVariables={{
        status: 'PENDING',
      }}
      slots={{
        header: PaperHeader,
        table: FingoTable,
        actions: OperativeRequestActions,
      }}
      slotProps={{
        header: {
          viewTitle: 'Solicitudes operacionales',
        },
        table: {
          columns: OPERATIVE_REQUESTS_COLUMNS,
          includeHeaders: [
            'id',
            'requester',
            'operativeRequestType',
            'documentType',
            'comment',
          ],
          checkboxSelection: true,
          collapsible: true,
          collapseComponent: CollapseOperativeRequest,
          isRowSelectable: () => true,
          isRowCollapsible: () => true,
          disableSelectionOnClick: true,
          onSelectionModelChange: (ids) => { setSelectedRequest(ids); },
          selectionModel: _selectedRequest,
          noRowsMessage: () => (
            <Typography>¡Felicidades! No hay solicitudes pendientes.
            </Typography>
          ),
          rowsPerPageOptions: [15, 20, 50],
        },
        actions: {
          selectedRequest,
        },
      }}
    />
  );
};

CollapseOperativeRequest.propTypes = {
  row: PropTypes.shape({
    operativerequestmanagerSet: PropTypes.shape({
      id: PropTypes.string,
      invoice: PropTypes.shape({
        id: PropTypes.string,
        folio: PropTypes.string,
        amountWithIva: PropTypes.number,
      }),
    }),
  }).isRequired,
};

export default OperativeRequests;
