import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

const numberFormatProps = {
  decimalSeparator: ',',
  thousandSeparator: '.',
  decimalScale: 0,
  fixedDecimalScale: true,
  valueIsNumericString: true,
  prefix: '$',
  customInput: TextField,
};

const CommissionForm = ({ operativeRequest, setParameters }) => {
  const [commission, _setCommission] = useState();
  const setCommission = (value) => {
    _setCommission(value);
    setParameters([{
      key: 'commission',
      value,
    }]);
  };
  return (
    <>
      <Typography variant="h4" sx={{ my: 2 }} textAlign="center">
        {operativeRequest.operativeRequestType?.name}
      </Typography>
      <NumericFormat
        id="change-commission"
        name="monthlyRate"
        value={commission}
        onValueChange={(values) => setCommission(values.value)}
        {...numberFormatProps}
      />
    </>
  );
};

CommissionForm.propTypes = {
  operativeRequest: PropTypes.shape({
    id: PropTypes.string,
    operativeRequestType: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    operativerequestmanagerSet: PropTypes.arrayOf({
      id: PropTypes.string,
      invoice: PropTypes.shape({
        id: PropTypes.string,
        folio: PropTypes.string,
      }),
    }),
  }).isRequired,
  setParameters: PropTypes.func.isRequired,
};

export default CommissionForm;
