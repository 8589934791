import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const SimpleForm = ({ operativeRequest }) => {
  const len = operativeRequest.operativerequestmanagerSet?.length;
  const plural = len > 1 ? 's' : '';
  return (
    <>
      <Typography variant="h4" sx={{ my: 2 }} textAlign="center">
        {operativeRequest.operativeRequestType?.name}
      </Typography>
      <Typography variant="subtitle2">
        ¿Seguro que deseas aceptar esta solicitud
        para modificar {len} documento{plural}?
      </Typography>
    </>
  );
};

SimpleForm.propTypes = {
  operativeRequest: PropTypes.shape({
    id: PropTypes.string,
    operativeRequestType: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    operativerequestmanagerSet: PropTypes.arrayOf({
      id: PropTypes.string,
      invoice: PropTypes.shape({
        id: PropTypes.string,
        folio: PropTypes.string,
        amountWithIva: PropTypes.number,
      }),
    }),
  }).isRequired,
};

export default SimpleForm;
