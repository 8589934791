import { useQuery } from '@apollo/client';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { GET_OPERATIVE_REQUEST } from '@fingo/lib/graphql';
import { useBooleanState } from '@fingo/lib/hooks';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAcceptOperativeRequest } from '../../../hooks';
import { RatesForm, SimpleForm, CommissionForm } from './AcceptRequestForms';

const requestTypeToForm = {
  MOVE_TO_PENDINGRATIFICATION: SimpleForm,
  MOVE_TO_PENDINGDOCUMENTSREVIEW: SimpleForm,
  CHANGE_OPERATION_CONDITIONS: RatesForm,
  CHANGE_TO_INTERNAL_CESION: SimpleForm,
  CHANGE_COMMISSION: CommissionForm,
  UNDO_CONCILIATION: SimpleForm,
};

const AcceptRequestButton = ({
  operativeRequestId,
}) => {
  // states
  const [acceptRequestParams, setAcceptRequestParams] = useState([]);
  const [openDialog, toggleDialog] = useBooleanState();
  const [acceptOperativeRequest, loadingMutation] = useAcceptOperativeRequest({
    variables: {
      operativeRequestId,
      parameters: acceptRequestParams,
    },
    onCompleted: () => {
      toggleDialog();
      setAcceptRequestParams([]);
    },
  });

  const { data, loading: loadingQuery } = useQuery(
    GET_OPERATIVE_REQUEST,
    { variables: { operativeRequestId }, skip: !openDialog },
  );

  // variables
  const operativeRequest = data ? data.getOperativeRequest : {};
  const FormComponent = isEmpty(operativeRequest)
    ? SimpleForm
    : requestTypeToForm[operativeRequest.operativeRequestType.code];

  return (
    <>
      <IconButton
        onClick={toggleDialog}
        disabled={isEmpty(operativeRequestId)}
      >
        <CheckCircle color="success" />
      </IconButton>
      <FingoDialog
        open={openDialog}
        handleClose={toggleDialog}
        title={`Aceptar solicitud N°${operativeRequestId}`}
        dialogActionButton={(
          <LoadingButton
            variant="contained"
            size="small"
            loading={loadingMutation}
            onClick={acceptOperativeRequest}
          >
            Aceptar
          </LoadingButton>
        )}
      >
        {loadingQuery ? (
          <CircularProgress />
        ) : (
          <FormComponent
            operativeRequest={operativeRequest}
            setParameters={setAcceptRequestParams}
          />
        )}
      </FingoDialog>
    </>
  );
};

AcceptRequestButton.propTypes = {
  operativeRequestId: PropTypes.string.isRequired,
};

export default AcceptRequestButton;
