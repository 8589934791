import { useMutation } from '@apollo/client';
import { ACCEPT_OPERATIVE_REQUEST } from '@fingo/lib/graphql';
import { useSnackBars } from '@fingo/lib/hooks';

const useAcceptOperativeRequest = ({ variables, onCompleted }) => {
  const { addAlert } = useSnackBars();
  const [
    acceptOperativeRequest,
    { loading },
  ] = useMutation(ACCEPT_OPERATIVE_REQUEST, {
    refetchQueries: ['operativeRequests'],
    variables,
    onCompleted: () => {
      onCompleted();
      addAlert({ id: 'accept-operative-request', color: 'success', message: '¡Solicitud aceptada!' });
    },
    onError: (err) => {
      addAlert({ id: 'success-operative-request', color: 'error', message: `Error: ${err.message[0]}` });
    },
  });
  return [acceptOperativeRequest, loading];
};

export default useAcceptOperativeRequest;
